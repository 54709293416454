<template>
    <div class="container">
        <el-table :data="deviceList" v-loading="loading" element-loading-text="加载中...">
            <el-table-column label="主机名称">
                <template slot-scope="scope">
                    {{scope.row.name}} ({{scope.row.ip}})
                </template>
            </el-table-column>
            <el-table-column label="位置">
                <template slot-scope="scope">
                    {{scope.row.room}}
                </template>
            </el-table-column>
            <el-table-column label="应用占用端口" prop="soft_ports">
            </el-table-column>
            <el-table-column label="主机开放端口" prop="safe_ports">
            </el-table-column>
            <el-table-column label="防火墙准入端口" prop="firewall_ports">
            </el-table-column>
            <el-table-column label="详情" width="100">
                <template slot-scope="scope">
                    <el-link type="primary" icon="el-icon-document" :href="'/#/device/detail?id='+scope.row.id">详情</el-link>
                </template>
            </el-table-column>
        </el-table>
        <div class="pagenation">
            <el-pagination :current-page="currentPage" layout="total, prev, pager, next" :page-size="pageSize" :total="total" @current-change="handleCurrentChange">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DevicePort",
        props: {
            name: {
                type: String
            }
        },
        data() {
            return {
                loading: false,
                deviceList: [],
                currentPage: 1,
                total: 0,
                pageSize: 8,
                hidepage: true,
            }
        },
        mounted() {
            if (this.name == 'safe_ports') {
                this.getListData();
            }
        },
        methods: {
            //获取主机设备列表
            getListData() {
                this.loading = true;
                this.$get(this.$api.GetReportDevicePortList, {field: this.refField, tag: this.activeName, type: 1, page: this.currentPage, limit: this.pageSize}).then((res) => {
                    this.loading = false;
                    if (res.code === 0) {
                        this.deviceList = res.data.list;
                        this.total = res.data.total_count;
                    } else {
                        this.$message.error(res.message);
                    }
                });
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.getListData();
            },
        }
    }
</script>

<style scoped lang="less">
    .pagenation{
        text-align: center;
        margin-top: 10px;
    }
</style>