<template>
  <div class="container">
    <div class="page-title">
      <h3>安全报告</h3>
    </div>

    <el-row>
      <el-col :span="12">
        <el-card class="box-card" shadow="never">
          <div slot="header" class="clearfix">
            <span>设备分布</span>
          </div>
          <div ref="devcateChart" style="width:100%;height: 520px"></div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card class="box-card" shadow="never">
          <div slot="header" class="clearfix">
            <span>应用分布</span>
          </div>
          <div ref="appcateChart" style="width:100%;height: 520px"></div>
        </el-card>
      </el-col>
    </el-row>
    <!--安全设备覆盖度-->
    <div class="box-card">
      <el-tabs v-model="activeSafeName" type="card" @tab-click="tabClick">
        <el-tab-pane label="防火墙覆盖度" name="firewall">
          <hw-safe-chart name="firewall" :type-id="7" v-if="activeSafeName=='firewall'"></hw-safe-chart>
        </el-tab-pane>
        <el-tab-pane label="VPN覆盖度" name="vpn">
          <hw-safe-chart name="vpn" :type-id="11" v-if="activeSafeName=='vpn'"></hw-safe-chart>
        </el-tab-pane>
        <el-tab-pane label="日志审计" name="log">
          <hw-safe-chart name="log" :type-id="23" v-if="activeSafeName=='log'"></hw-safe-chart>
        </el-tab-pane>
        <el-tab-pane label="堡垒机"  name="fortress">
          <hw-safe-chart name="fortress" :type-id="24" v-if="activeSafeName=='fortress'"></hw-safe-chart>
        </el-tab-pane>
        <el-tab-pane label="安全检测" name="inspection">
          <hw-safe-chart name="inspection" :type-id="25" v-if="activeSafeName=='inspection'"></hw-safe-chart>
        </el-tab-pane>
        <el-tab-pane label="准入网关" name="gateway">
          <hw-safe-chart name="gateway" :type-id="26" v-if="activeSafeName=='gateway'"></hw-safe-chart>
        </el-tab-pane>
        <el-tab-pane label="安全准入" name="access">
          <hw-safe-chart name="access" :type-id="27" v-if="activeSafeName=='access'"></hw-safe-chart>
        </el-tab-pane>
        <el-tab-pane label="网闸" name="gate">
          <hw-safe-chart name="gate" :type-id="28" v-if="activeSafeName=='gate'"></hw-safe-chart>
        </el-tab-pane>
        <el-tab-pane label="IPS" name="ips">
          <hw-safe-chart name="ips" :type-id="30" v-if="activeSafeName=='ips'"></hw-safe-chart>
        </el-tab-pane>
        <el-tab-pane label="备份" name="backup">
          <hw-safe-chart name="backup" :type-id="29" v-if="activeSafeName=='backup'"></hw-safe-chart>
        </el-tab-pane>
        <el-tab-pane label="上网行为管理" name="internet">
          <hw-safe-chart name="internet" :type-id="31" v-if="activeSafeName=='internet'"></hw-safe-chart>
        </el-tab-pane>
        <el-tab-pane label="防病毒" name="antivirus">
          <hw-safe-chart name="antivirus" :type-id="32" v-if="activeSafeName=='antivirus'"></hw-safe-chart>
        </el-tab-pane>
      </el-tabs>
    </div>

    <div class="box-card">
      <el-tabs v-model="activeAreaName" type="card" @tab-click="tabAreaClick">
        <el-tab-pane label="杀毒软件覆盖度" name="vir">
          <hw-chart name="vir" mode="soft" v-if="activeAreaName=='vir'"></hw-chart>
        </el-tab-pane>
        <el-tab-pane label="主机端口信息" name="safe_ports">
          <hw-device-port name="safe_ports" v-if="activeAreaName=='safe_ports'"></hw-device-port>
        </el-tab-pane>
        <el-tab-pane label="主机账号安全" name="safe_account">
          <hw-radar-chart name="safe_account" v-if="activeAreaName=='safe_account'"></hw-radar-chart>
        </el-tab-pane>
        <el-tab-pane label="主机登录方式" name="safe_remote">
          <hw-radar-chart name="safe_remote" v-if="activeAreaName=='safe_remote'"></hw-radar-chart>
        </el-tab-pane>
        <el-tab-pane label="数据加密" name="safe_data">
          <hw-sunburst-chart name="safe_data" v-if="activeAreaName=='safe_data'"></hw-sunburst-chart>
        </el-tab-pane>
        <el-tab-pane label="多因素认证" name="safe_auth">
          <hw-sunburst-chart name="safe_auth" v-if="activeAreaName=='safe_auth'"></hw-sunburst-chart>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
  import * as echarts from 'echarts';
  import Chart from "@/components/pages/Chart";
  import RadarChart from "@/components/pages/RadarChart";
  import SunburstChart from "@/components/pages/SunburstChart";
  import DevicePort from "@/components/pages/DevicePort";
  import SafeChart from "../components/pages/SafeChart";

  const optionDevcate = {
    title: {},
    series: {
      type: 'sunburst',
      data: [],
      radius: [0, '95%'],
      // sort: null,
      //
      // emphasis: {
      //     focus: 'ancestor'
      // },

      levels: [{}, {
        r0: '15%',
        r: '35%',
        itemStyle: {
          borderWidth: 2
        },
        label: {
          rotate: 'tangential',
          color: '#fff'
        }
      }, {
        r0: '35%',
        r: '78%',
        label: {
          align: 'center',
          color: '#fff'
        }

      }, {
        r0: '78%',
        r: '80%',
        label: {
          position: 'outside',
          padding: 3,
          silent: false
        },
        itemStyle: {
          borderWidth: 3
        }
      }]
    }
  };

  const optionAppcate = {
    title: {},
    tooltip: {
      formatter: '{b}'
    },
    series: {
      type: 'sunburst',
      data: [],
      radius: [0, '95%'],
      // sort: null,
      //
      // emphasis: {
      //     focus: 'ancestor'
      // },

      levels: [{}, {
        r0: '15%',
        r: '70%',
        itemStyle: {
          borderWidth: 2
        },
        label: {
          align: 'center',
          color: '#fff'
        }
      }, {
        r0: '70%',
        r: '75%',
        label: {
          position: 'outside',
          padding: 3,
          silent: false
        },
        itemStyle: {
          borderWidth: 3
        }
      }]
    }
  };

  export default {
    name: "Report",
    components: {
      hwChart: Chart,
      hwSafeChart: SafeChart,
      hwRadarChart: RadarChart,
      hwSunburstChart: SunburstChart,
      hwDevicePort: DevicePort
    },
    data() {
      return {
        loading: false,
        activeSafeName: 'firewall',
        activeAreaName: 'vir',
        // activeNetAreaName: 'safe_intrude',
        // activeSoftAreaName: 'ref_waf',
      }
    },
    beforeRouteLeave(to, from, next) {
      this.clearChart();
      localStorage.removeItem('stoken');
      next();
    },
    mounted() {
      this.createDevcateChart();
      //this.createAppChart();
    },
    created() {
      //this.createDevcateChart();
    },
    methods: {
      //设备分布图
      createDevcateChart() {
        const myChart = echarts.init(this.$refs.devcateChart);
        myChart.showLoading();
        //myChart.setOption(optionDevcate, true);
        this.$get(this.$api.GetReportDevChart).then((res) => {
          myChart.hideLoading();
          optionDevcate.series.data = res.data;
          myChart.setOption(optionDevcate);
          this.createAppChart();
          //点击更多跳转到关联设备列表
          myChart.off('click');
          myChart.on('click', (param) => {
            let data = param.data;
            if (data.name === '...') {
              this.$router.push({path: data.path, query: {type_id: data.typeid}});
            }
          });
        });
      },
      clearChart() {
        const myChart = echarts.init(this.$refs.devcateChart);
        //myChart.clear();
        optionDevcate.series.data = [];
        myChart.setOption(optionDevcate);
        myChart.dispose(); //销毁实例
      },

      //应用分布图
      createAppChart() {
        const myChart = echarts.init(this.$refs.appcateChart);
        myChart.showLoading();
        //myChart.setOption(optionAppcate, true);
        this.$get(this.$api.GetReportAppChart).then((res) => {
          myChart.hideLoading();
          optionAppcate.series.data = res.data;
          myChart.setOption(optionAppcate);
        });
      },

      //点击切换tab-主机覆盖
      tabClick(tab) {
        this.activeSafeName = tab.name;
      },
      tabAreaClick(tab) {
        this.activeAreaName = tab.name;
      }
    }
  }
</script>

<style scoped lang="less">
  .box-card {
    margin: 10px;
  }

  .pagenation {
    text-align: center;
    margin-top: 10px;
  }

  /deep/ .el-tabs__header {
    margin: 0;
  }
</style>
