<template>
  <div class="container">
    <el-row>
      <el-col :span="12">
        <div ref="accountChart" style="width:100%;height: 520px" v-show="name=='safe_account'"></div>
        <div ref="loginChart" style="width:100%;height: 520px" v-show="name=='safe_remote'"></div>
        <div ref="portChart" style="width:100%;height: 520px" v-show="name=='safe_ports'"></div>
        <div ref="passwordChart" style="width:100%;height: 520px" v-show="name=='safe_app_password'"></div>
      </el-col>
      <el-col :span="12" style="padding-right: 20px">
        <div class="table-title">
          {{tabTitle}}
        </div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane :label="item.name" :name="item.name" v-for="(item,index) in tablist" :key="index"></el-tab-pane>
        </el-tabs>
        <el-table :data="deviceList" v-loading="loading" element-loading-text="加载中...">
          <el-table-column label="主机名称" width="320">
            <template slot-scope="scope">
              {{scope.row.name}} ({{scope.row.ip}})
            </template>
          </el-table-column>
          <el-table-column label="位置" width="280">
            <template slot-scope="scope">
              {{scope.row.room}} {{scope.row.cab}}
            </template>
          </el-table-column>
          <el-table-column label="详情">
            <template slot-scope="scope">
              <el-link type="primary" icon="el-icon-document" :href="'/#/device/detail?id='+scope.row.id">详情</el-link>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagenation">
          <el-pagination :current-page="currentPage" layout="total, prev, pager, next" :page-size="pageSize"
                         :total="total" @current-change="handleCurrentChange">
          </el-pagination>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import * as echarts from 'echarts';

  //账号安全配置
  const optionAccount = {
    tooltip: {
      //trigger: 'axis',
      axisPointer: {            // Use axis to trigger tooltip
        type: 'shadow'        // 'shadow' as default; can also be 'line' or 'shadow'
      }
    },
    radar: {
      // shape: 'circle',
      name: {
        textStyle: {
          color: '#333'
        }
      },
      indicator: []
    },
    series: [{
      name: '主机账号安全',
      type: 'radar',
      areaStyle: {
        color: '#ff6600'
      },
      data: [
        {
          value: [],
          name: '主机账号安全'
        }
      ]
    }]
  };
  //登录方式配置
  const optionLogin = {
    tooltip: {},
    radar: {
      // shape: 'circle',
      name: {
        textStyle: {
          color: '#333'
        }
      },
      indicator: []
    },
    series: [{
      name: '主机登录方式',
      type: 'radar',
      areaStyle: {
        color: '#ff6600'
      },
      data: [
        {
          value: [],
          name: '主机登录方式'
        }
      ]
    }]
  };
  //端口覆盖
  const optionPort = {
    tooltip: {},
    radar: {
      // shape: 'circle',
      name: {
        textStyle: {
          color: '#333'
        }
      },
      indicator: []
    },
    series: [{
      name: '端口覆盖',
      type: 'radar',
      areaStyle: {
        color: '#ff6600'
      },
      data: [
        {
          value: [],
          name: '端口覆盖'
        }
      ]
    }]
  };

  //软件弱密码
  const optionPassword = {
    tooltip: {},
    radar: {
      // shape: 'circle',
      name: {
        textStyle: {
          color: '#333'
        }
      },
      indicator: []
    },
    series: [{
      name: '软件弱密码',
      type: 'radar',
      areaStyle: {
        color: '#ff6600'
      },
      data: [
        {
          value: [],
        }
      ]
    }]
  };

  export default {
    name: "SafedeviceChart",
    props: {
      name: {
        type: String
      }
    },
    data() {
      return {
        loading: false,
        deviceList: [],
        currentPage: 1,
        total: 0,
        pageSize: 6,
        hidepage: true,
        tabTitle: '未',
        activeName: '0',
        tablist: [],
        refField: '',
      }
    },
    mounted() {
      if (this.name == 'safe_account') {
        this.createAccountChart();
      } else if (this.name == 'safe_remote') {
        this.createLoginChart();
      } else if (this.name == 'safe_ports') {
        this.createPortChart();
      } else if (this.name == 'safe_app_password') {
        this.createPasswordChart();
      } else {
        //
      }

    },
    methods: {
      //账号安全分析图
      createAccountChart() {
        const myChart = echarts.init(this.$refs.accountChart);
        this.tabTitle = '主机账号安全';
        this.refField = 'safe_account';
        myChart.showLoading();
        this.$get(this.$api.GetReportHostAccoutSafe).then((res) => {
          myChart.hideLoading();
          optionAccount.radar.indicator = res.indicator;
          optionAccount.series[0].data[0] = res.data;
          myChart.setOption(optionAccount);
          this.tablist = res.indicator;
          this.activeName = this.tablist[0].name;
          this.getPopTagData();
        });
      },
      //主机登录方式
      createLoginChart() {
        const myChart = echarts.init(this.$refs.loginChart);
        this.tabTitle = '主机登录方式';
        this.refField = 'safe_remote';
        myChart.showLoading();
        this.$get(this.$api.GetReportHostLoginSafe).then((res) => {
          myChart.hideLoading();
          optionLogin.radar.indicator = res.indicator;
          optionLogin.series[0].data[0] = res.data;
          myChart.setOption(optionLogin);
          this.tablist = res.indicator;
          this.activeName = this.tablist[0].name;
          this.getPopTagData();
        });
      },

      //端口覆盖
      createPortChart() {
        const myChart = echarts.init(this.$refs.portChart);
        this.tabTitle = '端口覆盖';
        this.refField = 'safe_ports';
        myChart.showLoading();
        this.$get(this.$api.GetReportHostPortSafe).then((res) => {
          myChart.hideLoading();
          optionPort.radar.indicator = res.indicator;
          optionPort.series[0].data[0] = res.data;
          myChart.setOption(optionPort);

          this.tablist = res.indicator;
          this.activeName = this.tablist[0].name;
          this.getPopTagData();
        });
      },

      //弱密码
      createPasswordChart() {
        const myChart = echarts.init(this.$refs.passwordChart);
        this.tabTitle = '主机应用软件弱密码';
        this.refField = 'safe_app_password';
        myChart.showLoading();
        this.$get(this.$api.GetReportPasswordChart).then((res) => {
          myChart.hideLoading();
          optionPassword.radar.indicator = res.indicator;
          optionPassword.series[0].data[0] = res.data;
          myChart.setOption(optionPassword);
          this.tablist = res.indicator;
          this.activeName = this.tablist[0].name;
          this.getPopTagData();
        });
      },

      handleClick() {
        //console.log(tab.name, tab.label)
        this.getPopTagData();
      },

      //获取标签关联的主机设备
      getPopTagData() {
        this.loading = true;
        this.$get(this.$api.GetPopTagData, {
          field: this.refField,
          tag: this.activeName,
          page: this.currentPage,
          limit: this.pageSize
        }).then((res) => {
          this.loading = false;
          if (res.code === 0) {
            this.deviceList = res.data.list;
            this.total = res.data.total_count;
          } else {
            this.$message.error(res.message);
          }
        });
      },
      handleCurrentChange(val) {
        this.currentPage = val;
        this.getPopTagData();
      },
    }
  }
</script>

<style scoped lang="less">
  .container {
    border-left: 1px solid #E4E7ED;
    border-right: 1px solid #E4E7ED;
    border-bottom: 1px solid #E4E7ED;
  }

  .table-title {
    height: 42px;
    line-height: 42px;
  }

  .pagenation {
    text-align: center;
    margin-top: 10px;
  }
</style>
