<template>
    <div class="container">
        <el-row>
            <el-col :span="12">
                <div ref="firewallChart" style="width:100%;height: 520px" v-show="name=='firewall'"></div>
                <div ref="virChart" style="width:100%;height: 520px" v-show="name=='vir'"></div>
                <div ref="intrudeChart" style="width:100%;height: 520px" v-show="name=='safe_intrude'"></div>
                <div ref="defensChart" style="width:100%;height: 520px" v-show="name=='safe_defens'"></div>
                <div ref="internetChart" style="width:100%;height: 520px" v-show="name=='safe_internet'"></div>
                <div ref="allowChart" style="width:100%;height: 520px" v-show="name=='safe_allow'"></div>
            </el-col>
            <el-col :span="12">
                <div class="table-title">
                    {{tabTitle}}覆盖的主机({{total}}台)
                </div>
                <el-table :data="deviceList" v-loading="loading" element-loading-text="加载中..." width="98%">
                    <el-table-column label="名称" width="300">
                        <template slot-scope="scope">
                            {{scope.row.name}} ({{scope.row.ip}})
                        </template>
                    </el-table-column>
                    <el-table-column label="位置" width="260">
                        <template slot-scope="scope">
                            {{scope.row.room}}
                        </template>
                    </el-table-column>
                    <el-table-column label="详情">
                        <template slot-scope="scope">
                            <el-link type="primary" icon="el-icon-document" :href="'/#/device/detail?id='+scope.row.id">详情</el-link>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagenation">
                    <el-pagination :current-page="currentPage" layout="total, prev, pager, next" :page-size="pageSize" :total="total" @current-change="handleCurrentChange">
                    </el-pagination>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import * as echarts from 'echarts';

    //防火墙覆盖配置
    const optionFirewall = {
        legend: {
            //top: 'bottom',
            bottom: 40
        },
        tooltip: {
            trigger: 'item',
            formatter: '<span style="font-weight: bold">{b}</span><br />{a}: {c}台（{d}%）'
        },
        series: [
            {
                name: '设备',
                type: 'pie',
                radius: [50, 150],
                center: ['40%', '40%'],
                roseType: 'area',
                itemStyle: {
                    borderRadius: 8
                },
                data: []
            }
        ]
    }

    //杀毒防护覆盖配置
    const optionVir = {
        legend: {
            bottom: 40
        },
        tooltip: {
            trigger: 'item',
            formatter: '<span style="font-weight: bold">{b}</span><br />{a}: {c}台（{d}%）'
        },
        series: [
            {
                name: '主机',
                type: 'pie',
                radius: [50, 150],
                center: ['40%', '40%'],
                roseType: 'area',
                itemStyle: {
                    borderRadius: 8
                },
                data: []
            }
        ]
    };

    //入侵检测覆盖配置
    const optionIntrude = {
        legend: {
            bottom: 40
        },
        tooltip: {
            trigger: 'item',
            formatter: '<span style="font-weight: bold">{b}</span><br />{a}: {c}台（{d}%）'
        },
        series: [
            {
                name: '主机',
                type: 'pie',
                radius: [50, 150],
                center: ['40%', '40%'],
                roseType: 'area',
                itemStyle: {
                    borderRadius: 8
                },
                data: []
            }
        ]
    };

    //主机防护覆盖配置
    const optionDefens = {
        legend: {
            bottom: 40
        },
        tooltip: {
            trigger: 'item',
            formatter: '<span style="font-weight: bold">{b}</span><br />{a}: {c}台（{d}%）'
        },
        series: [
            {
                name: '主机',
                type: 'pie',
                radius: [50, 150],
                center: ['40%', '40%'],
                roseType: 'area',
                itemStyle: {
                    borderRadius: 8
                },
                data: []
            }
        ]
    };

    //通用扇形图配置
    const option = {
        legend: {
            bottom: 40
        },
        tooltip: {
            trigger: 'item',
            formatter: '<span style="font-weight: bold">{b}</span><br />{a}: {c}台（{d}%）'
        },
        series: [
            {
                name: '主机',
                type: 'pie',
                radius: [50, 150],
                center: ['40%', '40%'],
                roseType: 'area',
                itemStyle: {
                    borderRadius: 8
                },
                data: []
            }
        ]
    };



    export default {
        name: "Chart",
        props: {
            name: {
                type: String
            },
            mode: {
                type: String
            }
        },
        data() {
            return {
                loading: false,
                showPop: false,
                deviceList: [],
                currentPage: 1,
                total: 0,
                pageSize: 6,
                hidepage: true,
                tabTitle: '未',
                curAreaId: 0,
                areaType: 24,
                refField: null,
                activeName: '0',
                tablist: [],
            }
        },
        mounted() {
            switch(this.name) {
                case 'firewall':
                    this.createFirewallChart();
                    break;
                case 'vir':
                    this.createVirChart();
                    break;
                case 'safe_intrude':
                    this.createIntrudeChart();
                    break;
                case 'safe_defens':
                    this.createDefensChart();
                    break;
                case 'safe_internet':
                    this.createInternetChart();
                    break;
                case 'safe_allow':
                    this.createAllowChart();
                    break;
            }
        },
        methods: {
            //防火墙覆盖度图
            createFirewallChart() {
                const myChart = echarts.init(this.$refs.firewallChart);
                myChart.showLoading();
                this.$get(this.$api.GetReportFirewallChart).then((res) => {
                    myChart.hideLoading();
                    optionFirewall.series[0].data = res.data;
                    myChart.setOption(optionFirewall);
                    this.areaType = 7;
                    this.getPopSafeListData(0, this.areaType);
                    //点击显示关联设备
                    myChart.off('click');
                    myChart.on('click', (param) => {
                        this.tabTitle = param.data.id==0 ? '未' : param.data.name;
                        this.curAreaId = param.data.id;
                        this.getPopSafeListData(this.curAreaId, this.areaType);
                    });
                    //this.createVirChart();
                });
            },
            //杀毒防护覆盖度图
            createVirChart() {
                const myChart = echarts.init(this.$refs.virChart);
                myChart.showLoading();
                this.$get(this.$api.GetReportVirChart).then((res) => {
                    myChart.hideLoading();
                    optionVir.series[0].data = res.data;
                    myChart.setOption(optionVir);
                    this.refField = 'host_vir';
                    this.getPopSoftListData(0, this.refField); //获取杀毒防护软件覆盖的主机设备
                    //点击显示关联设备
                    myChart.off('click');
                    myChart.on('click', (param) => {
                        this.tabTitle = param.data.id==0 ? '未' : param.data.name;
                        this.curAreaId = param.data.id;
                      this.currentPage = 1;
                        this.getPopSoftListData(this.curAreaId, this.refField); //获取杀毒防护软件覆盖的主机设备
                    });
                });
            },

            //关联入侵检测
            createIntrudeChart() {
                const myChart = echarts.init(this.$refs.intrudeChart);
                myChart.showLoading();
                this.$get(this.$api.GetReportIntrudeChart).then((res) => {
                    myChart.hideLoading();
                    optionIntrude.series[0].data = res.data;
                    myChart.setOption(optionIntrude);
                    this.refField = 'host_ref_intrude';
                    this.getPopSoftListData(0, this.refField); //获取关联入侵检测软件覆盖的主机设备
                    //点击显示关联设备
                    myChart.off('click');
                    myChart.on('click', (param) => {
                        this.tabTitle = param.data.id==0 ? '未' : param.data.name;
                        this.curAreaId = param.data.id;
                        this.getPopSoftListData(this.curAreaId, this.refField); //获取杀毒防护软件覆盖的主机设备
                    });
                    //this.createDefensChart();
                });
            },
            //关联主动防御
            createDefensChart() {
                const myChart = echarts.init(this.$refs.defensChart);
                myChart.showLoading();
                this.$get(this.$api.GetReportDefensChart).then((res) => {
                    myChart.hideLoading();
                    optionDefens.series[0].data = res.data;
                    myChart.setOption(optionDefens);
                    this.refField = 'host_ref_defens';
                    this.getPopSoftListData(0, this.refField); //获取关联主机防护软件覆盖的主机设备
                    //点击显示关联设备
                    myChart.off('click');
                    myChart.on('click', (param) => {
                        this.tabTitle = param.data.id==0 ? '未' : param.data.name;
                        this.curAreaId = param.data.id;
                        this.getPopSoftListData(this.curAreaId, this.refField); //获取关联主机防护软件覆盖的主机设备
                    });
                });
            },
            //关联上网行为审计
            createInternetChart() {
                const myChart = echarts.init(this.$refs.internetChart);
                myChart.showLoading();
                this.refField = 'host_ref_internet';
                this.$get(this.$api.GetReportSoftChart, {field: this.refField}).then((res) => {
                    myChart.hideLoading();
                    option.series[0].data = res.data;
                    myChart.setOption(option);
                    this.getPopSoftListData(0, this.refField); //获取关联上网行为审计覆盖的主机设备
                    //点击显示关联设备
                    myChart.off('click');
                    myChart.on('click', (param) => {
                        this.tabTitle = param.data.id==0 ? '未' : param.data.name;
                        this.curAreaId = param.data.id;
                        this.getPopSoftListData(this.curAreaId, this.refField); //获取关联上网行为审计软件覆盖的主机设备
                    });
                });
            },

            //关联网络准入
            createAllowChart() {
                const myChart = echarts.init(this.$refs.allowChart);
                myChart.showLoading();
                this.refField = 'host_ref_allow';
                this.$get(this.$api.GetReportSoftChart, {field: this.refField}).then((res) => {
                    myChart.hideLoading();
                    option.series[0].data = res.data;
                    myChart.setOption(option);
                    this.getPopSoftListData(0, this.refField); //获取关联关联网络准入覆盖的主机设备
                    //点击显示关联设备
                    myChart.off('click');
                    myChart.on('click', (param) => {
                        this.tabTitle = param.data.id==0 ? '未' : param.data.name;
                        this.curAreaId = param.data.id;
                        this.getPopSoftListData(this.curAreaId, this.refField); //获取关联关联网络准入软件覆盖的主机设备
                    });
                });
            },

            //切换分页数据
            handleCurrentChange(val) {
                this.currentPage = val;
                if (this.mode == 'dev') {
                    this.getPopSafeListData(this.curAreaId, this.areaType);
                } else {
                    this.getPopSoftListData(this.curAreaId, this.refField)
                }
            },
            //获取安全设备覆盖主机设备列表
            getPopSafeListData(id, type) {
                this.loading = true;
                let params = {
                    dev_id: id,
                    page: this.currentPage,
                    limit: this.pageSize
                };
                if (id == 0) { //未覆盖
                    params.firewall = -1;
                } else {
                    params.type_id = type;
                }
                this.$get(this.$api.GetSafeArea, params).then((res) => {
                    this.loading = false;
                    if (res.code === 0) {
                        this.deviceList = res.data.list;
                        this.total = res.data.total_count;
                        if (res.data.total_page > 1) {
                            this.hidepage = false;
                        }
                    } else {
                        this.$message.error(res.message);
                    }
                });
            },
            //获取关联应用软件覆盖的主机设备
            getPopSoftListData(id, refField) {
                this.loading = true;
                this.$get(this.$api.GetSoftHostArea, {soft_id: id, field: refField, page: this.currentPage, limit: this.pageSize}).then((res) => {
                    this.loading = false;
                    if (res.code === 0) {
                        this.deviceList = res.data.list;
                        this.total = res.data.total_count;
                        if (res.data.total_page > 1) {
                            this.hidepage = false;
                        }
                    } else {
                        this.$message.error(res.message);
                    }
                });
            },
        }
    }
</script>

<style scoped lang="less">
.container{
    border-left: 1px solid #E4E7ED;
    border-right: 1px solid #E4E7ED;
    border-bottom: 1px solid #E4E7ED;
}
    .table-title{
        height: 42px;
        line-height: 42px;
    }
.pagenation{
    text-align: center;
    margin-top: 10px;
}
</style>
