<template>
  <div class="container">
    <el-row>
      <el-col :span="12">
        <div ref="firewallChart" class="chart-box"></div>
      </el-col>
      <el-col :span="12">
        <div class="table-title">
          {{tabTitle}}覆盖的设备({{total}}台)
        </div>
        <el-table :data="deviceList" v-loading="loading" element-loading-text="加载中..." width="98%">
          <el-table-column label="名称" width="300">
            <template slot-scope="scope">
              {{scope.row.name}} ({{scope.row.ip}})
            </template>
          </el-table-column>
          <el-table-column label="位置" width="260">
            <template slot-scope="scope">
              {{scope.row.roomname}} / {{scope.row.cab}}
            </template>
          </el-table-column>
          <el-table-column label="详情">
            <template slot-scope="scope">
              <el-link type="primary" icon="el-icon-document" @click="goPage(scope.row)">详情</el-link>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagenation">
          <el-pagination :current-page="currentPage" layout="total, prev, pager, next" :page-size="pageSize"
                         :total="total" @current-change="handleCurrentChange">
          </el-pagination>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import * as echarts from 'echarts';

  //防火墙覆盖配置
  const optionFirewall = {
    legend: {
      //top: 'bottom',
      bottom: 40
    },
    tooltip: {
      trigger: 'item',
      formatter: '<span style="font-weight: bold">{b}</span><br />{a}: {c}台（{d}%）'
    },
    series: [
      {
        name: '设备',
        type: 'pie',
        radius: [50, 150],
        center: ['40%', '40%'],
        roseType: 'area',
        itemStyle: {
          borderRadius: 8
        },
        data: []
      }
    ]
  }

  export default {
    name: "SafeChart",
    props: {
      name: {
        type: String
      },
      typeId: {
        type: Number
      },
      mode: {
        type: String
      }
    },
    data() {
      return {
        loading: false,
        showPop: false,
        deviceList: [],
        currentPage: 1,
        total: 0,
        pageSize: 6,
        hidepage: true,
        tabTitle: '未',
        curAreaId: 0,
        areaType: 24,
        refField: null,
        activeName: '0',
        tablist: [],
      }
    },
    mounted() {
      this.createSafeChart();
    },
    methods: {
      //安全设备覆盖图
      createSafeChart() {
        const myChart = echarts.init(this.$refs.firewallChart);
        myChart.showLoading();
        this.$get(this.$api.GetReportSafeChart, {type_id: this.typeId}).then((res) => {
          myChart.hideLoading();
          optionFirewall.series[0].data = res.data;
          myChart.setOption(optionFirewall);
          this.currentPage = 1;
          this.getPopSafeListData(0);
          //点击显示关联设备
          myChart.off('click');
          myChart.on('click', (param) => {
            this.tabTitle = param.data.id == 0 ? '未' : param.data.name;
            this.curAreaId = param.data.id;
            this.currentPage = 1;
            this.getPopSafeListData(this.curAreaId);
          });
          //this.createVirChart();
        });
      },
      //切换分页数据
      handleCurrentChange(val) {
        this.currentPage = val;
        this.getPopSafeListData(this.curAreaId);
      },
      //获取安全设备覆盖主机设备列表
      getPopSafeListData(refId) {
        this.loading = true;
        let params = {
          type_id: this.typeId,
          ref_id: refId,
          page: this.currentPage,
          limit: this.pageSize
        };
        this.$get(this.$api.GetSafeCoverList, params).then((res) => {
          this.loading = false;
          if (res.code === 0) {
            this.deviceList = res.data.list;
            this.total = res.data.total_count;
            if (res.data.total_page > 1) {
              this.hidepage = false;
            }
          } else {
            this.$message.error(res.message);
          }
        });
      },
      //跳转到页面
      goPage(row) {
        let path = '';
        if (row.type == 1) {
          path = '/device/detail';
        } else if (row.type == 2) {
          path = '/safe/detail';
        } else if (row.type == 3) {
          path = '/other/detail';
        } else {
          path = '/network/detail'
        }
        this.$router.push({path: path, query: {id: row.id}})
      }
    }
  }
</script>

<style scoped lang="less">
  .container {
    border-left: 1px solid #E4E7ED;
    border-right: 1px solid #E4E7ED;
    border-bottom: 1px solid #E4E7ED;
  }
  .chart-box{
    width:100%;
    height: 520px;
  }
  .table-title {
    height: 42px;
    line-height: 42px;
  }

  .pagenation {
    text-align: center;
    margin-top: 10px;
  }
</style>
