<template>
    <div class="container">
        <div ref="wafChart" style="width:100%;height: 620px" v-show="name=='ref_waf'"></div>
        <div ref="dbcheckChart" style="width:100%;height: 620px" v-show="name=='ref_dbcheck'"></div>
        <div ref="dataChart" style="width:100%;height: 620px" v-show="name=='safe_data'"></div>
        <div ref="authChart" style="width:100%;height: 620px" v-show="name=='safe_auth'"></div>
    </div>
</template>

<script>
    import * as echarts from 'echarts';

    const option = {
        series: {
            type: 'sunburst',
            data: [],
            radius: [0, '95%'],
            label: {
                rotate: 'radial'
            },
            levels: [{}, {
                r0: '15%',
                r: '40%',
                itemStyle: {
                    borderWidth: 2
                },
                label: {
                    rotate: 'tangential',
                    color: '#fff'
                }
            }, {
                r0: '40%',
                r: '80%',
                label: {
                    align: 'center',
                    color: '#fff'
                }

            }, {
                r0: '80%',
                r: '85%',
                label: {
                    position: 'outside',
                    padding: 3,
                    silent: false
                },
                itemStyle: {
                    borderWidth: 3
                }
            }]
        }
    };

    const optionWaf = {
        series: {
            type: 'sunburst',
            data: [],
            radius: [0, '95%'],
            label: {
                rotate: 'radial'
            },
            levels: [{}, {
                r0: '15%',
                r: '35%',
                itemStyle: {
                    borderWidth: 2
                },
                label: {
                    rotate: 'tangential',
                    color: '#fff'
                }
            }, {
                r0: '35%',
                r: '80%',
                label: {
                    align: 'center',
                    color: '#fff'
                }

            }, {
                r0: '80%',
                r: '85%',
                label: {
                    position: 'outside',
                    padding: 3,
                    silent: false
                },
                itemStyle: {
                    borderWidth: 3
                }
            }]
        }
    };

    const optionDb = {
        series: {
            type: 'sunburst',
            data: [],
            radius: [0, '95%'],
            label: {
                rotate: 'radial'
            },
            levels: [{}, {
                r0: '15%',
                r: '35%',
                itemStyle: {
                    borderWidth: 2
                },
                label: {
                    rotate: 'tangential',
                    color: '#fff'
                }
            }, {
                r0: '35%',
                r: '80%',
                label: {
                    align: 'center',
                    color: '#fff'
                }

            }, {
                r0: '80%',
                r: '85%',
                label: {
                    position: 'outside',
                    padding: 3,
                    silent: false
                },
                itemStyle: {
                    borderWidth: 3
                }
            }]
        }
    };

    export default {
        name: "SunburstChart",
        props: {
            name: {
                type: String
            }
        },
        data() {
            return {
                //
            }
        },
        mounted() {
            if (this.name == 'ref_waf') {
                this.createWafChart();
            } else if (this.name == 'ref_dbcheck') {
                this.createDbcheckChart();
            } else if (this.name == 'safe_data') {
                this.createDataChart();
            } else {
                this.createAuthChart();
            }
        },
        methods: {
            //WAF分布图
            createWafChart() {
                const myChart = echarts.init(this.$refs.wafChart);
                myChart.showLoading();
                this.$get(this.$api.GetReportSoftRefChart, {ref_id: 12}).then((res) => {
                    myChart.hideLoading();
                    optionWaf.series.data = res.data;
                    myChart.setOption(optionWaf);
                });
            },
            //数据库审计分布
            createDbcheckChart() {
                const myChart = echarts.init(this.$refs.dbcheckChart);
                myChart.showLoading();
                this.$get(this.$api.GetReportSoftRefChart, {ref_id: 13}).then((res) => {
                    myChart.hideLoading();
                    optionDb.series.data = res.data;
                    myChart.setOption(optionDb);
                });
            },
            //数据加密分布
            createDataChart() {
                const myChart = echarts.init(this.$refs.dataChart);
                myChart.showLoading();
                this.$get(this.$api.GetReportSafedataChart, {field: 'safe_data'}).then((res) => {
                    myChart.hideLoading();
                    option.series.data = res.data;
                    myChart.setOption(option);
                });
            },
            //多因素认证
            createAuthChart() {
                const myChart = echarts.init(this.$refs.authChart);
                myChart.showLoading();
                this.$get(this.$api.GetReportSafedataChart, {field: 'safe_auth'}).then((res) => {
                    myChart.hideLoading();
                    option.series.data = res.data;
                    myChart.setOption(option);
                  //点击更多跳转到关联设备列表
                  myChart.off('click');
                  myChart.on('click', (param) => {
                    let data = param.data;
                    if (data.name === '查看更多') {
                      this.$router.push({path: '/soft'});
                    } else {
                      this.$router.push({path: data.path, query: {id: data.id}});
                    }
                  });
                });
            }
        }
    }
</script>

<style scoped lang="less">
    .container{
        border-left: 1px solid #E4E7ED;
        border-right: 1px solid #E4E7ED;
        border-bottom: 1px solid #E4E7ED;
    }

</style>
